footer {
  background-color: #355e3b;
  color: #e2d7d7;
  width: 100vw;
  padding: 3rem 1rem;
  text-align: center;
  font-family: 'Oswald', sans-serif;
}

.container {
  max-width: 90rem;
  margin: auto;
}

.footer-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding-bottom: 2rem;
}

.footer-section {
  text-align: center;
}

.subscribe-section{
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.footer-logo {
  height: 3rem;
  width: 8rem;
  filter: brightness(90%);
  margin-bottom: 1rem;
}

.summary {
  font-size: 0.9rem;
  line-height: 1.5;
}

/* align links */
.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin: 0.5rem 0;
}

.footer-links a {
  text-decoration: none;
  color: #e2d7d7;
  font-size: 0.9rem;
  transition: color 0.3s ease-in-out;
}

.footer-links a:hover {
  color: #F3B70A;
}


.footer-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  color: #F3B70A;
}

.footer-input {
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.subscribe-btn {
  padding: 5px 8px;
  background-color: #F3B70A;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
  height: 2.8rem;
  margin-top: 0.5rem;
}

.subscribe-btn:hover {
  background-color: #d19d00;
}

.contact-list {
  list-style: none;
  padding: 0;
  font-size: 0.9rem;
}

.contact-list li {
  margin-bottom: 0.5rem;
}

.copyright {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .footer-row {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .summary {
      text-align: center;
  }

  .contact-list {
      text-align: left;
  }

  .footer-links {
      text-align: left;
  }
}

.products-container {
    margin: 0 auto;
    margin-bottom: 6.8rem;
    padding: 2rem;
    max-width: 80rem;
    background-color: #f7f7f2;
    border-radius: 0.625rem;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.products-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.5rem;
    color: #2c5f2d;
    margin-bottom: 1.5rem;
}

.inner-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr)); 
    grid-gap: 1.25rem;
}

.row {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
}

/* .row:hover {
    transform: scale(1.03);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
} */

.grid-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.img_inner {
    display: block;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 10px;
}

.extra_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 100%;
}

.card-button {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, #a3c585 5%, #506c2d 100%);
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
    font-family: "Arial", sans-serif;
    font-weight: bold;
    padding: 10px 16px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #3d5c2a;
    transition: all 0.3s ease-in-out;
    border: none;
}

.card-button:hover {
    background: linear-gradient(to bottom, #b5d698 5%, #4b662b 100%);
    transform: scale(1.05);
}

.card-button:active {
    position: relative;
    top: 1px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 8px;
}

.pagination button {
    border: 1px solid #85a04a;
    padding: 8px 12px;
    margin: 0 5px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.pagination button:hover {
    background-color: #c0d989;
}

.pagination button.active {
    background-color: #85a04a;
    color: #fff;
    transform: scale(1.1);
}

/* Mobile Responsive Design */
@media only screen and (max-width: 768px) {
    .products-container {
        padding: 1.5rem;
    }

    .products-title {
        font-size: 2rem;
    }

    .row {
        flex-direction: column;
        height: auto;
        text-align: center;
        padding: 15px;
    }

    .img_inner {
        width: 8rem;
        height: 8rem;
        margin-bottom: 0.625rem;
    }

    .inner-container {
        grid-template-columns: 1fr;
    }
}
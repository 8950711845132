.about-page {
    background-color: #f5f8f1;
    color: #2c3e1d;
    padding: 3rem 1rem;
    font-family: 'Poppins', sans-serif;
}

.about-page-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.5rem;
    color: #305d02;
}

.about-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
}

.about-section h3 {
    font-size: 1.5rem;
    color: #446629;
}

.about-section p {
    font-size: 1rem;
    line-height: 1.8;
}

video {
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.box-section {
    margin-top: 2rem;
    background-color: #eef3e6;
    padding: 2rem 0;
}

.for_box {
    background-color: #ffffff;
    border-radius: 0.625rem;
    padding: 2rem;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.for_box:hover {
    transform: translateY(-5px);
}

.for_box .icon {
    font-size: 2.2rem;
    color: #f3b70a;
}

.for_box span {
    font-size: 2rem;
    font-weight: bold;
    color: #305d02;
}

.for_box h3 {
    font-size: 1.3rem;
}

.service-block-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
}

.service-card {
    background-color: #fff;
    border-radius: 0.625rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    flex: 1 1 calc(30% - 1rem);
    min-width: 280px;
}

.service-card:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.service-block-inner {
    padding: 1.5rem;
    text-align: center;
}

.service-block-inner h3 {
    font-size: 1.4rem;
    color: #305d02;
    margin-bottom: 0.8rem;
}

.service-block-inner p {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #333;
}

/* Responsive Design */
@media (max-width: 991.98px) {
    .about-section {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .service-block-container {
        flex-direction: column;
        align-items: center;
    }

    .service-card {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .about-page-title {
        font-size: 2rem;
    }

    .for_box {
        padding: 1.5rem;
    }

    .service-card {
        width: 100%;
    }
}

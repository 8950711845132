.contact-page {
  background-image: url(../../media/tractor.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  min-height: 100vh;
}

/* Main Card */
.card {
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  width: 80%;
  max-width: 900px;
}

/* Card Header */
.card-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.card-header h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #2b614a;
}

.card-body {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.form-container {
  flex: 1;
  max-width: 450px;
  padding: 0 1rem;
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Georgia', sans-serif;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-container input:focus,
.form-container textarea:focus {
  outline: none;
  border-color: #2b614a;
  box-shadow: 0 0 5px rgba(43, 97, 74, 0.5);
}

.form-container button {
  padding: 0.75rem;
  background-color: #2b614a;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form-container button:hover {
  background-color: #357960;
}

.contact-info {
  flex: 1;
  max-width: 350px;
  text-align: center;
}

.contact-info h3 {
  font-size: 1.5rem;
  color: #2b614a;
}

.contact-info p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.contact-info .icon {
  font-size: 1.8rem;
  color: #F3B70A;
  margin: 0.5rem 0;
}

.social-media-links {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-media-links h3 {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 4rem;
  position: relative;
  top: -3rem;
  right: -8.5rem;
  margin-bottom: 3.5rem;
}

.social-media-links .icon {
  font-size: 2.5rem;
  margin: 0 0.5rem;
  color: #2b614a;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.social-media-links .icon:hover {
  color: #F3B70A;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .card {
      width: 90%;
      padding: 1.5rem;
  }

  .card-body {
      flex-direction: column;
      align-items: center;
  }

  .form-container, .contact-info {
      max-width: 100%;
  }

  .social-media-links {
      flex-direction: row;
  }
}

.product {
  flex-basis: 30%;
  flex-grow: 1;
  width: 26rem;
  height: 35rem;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; */
}

/* .product:hover {
  transform: scale(1.03);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
} */

.product-title {
  width: 90%;
  padding: 0.75rem 1.25rem;
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c5f2d;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.product-image {
  height: 18rem;
  width: 100%;
  object-fit: cover;
  opacity: 0.95;
  transition: opacity 0.3s ease-in-out;
}

.product:hover .product-image {
  opacity: 1;
}

.product-description {
  flex-grow: 1;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  color: #4a4a4a;
  text-align: center;
}

.button-section {
  background-color: #85a04a;
  padding: 0.75rem 1.25rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
}

.product-button {
  background: linear-gradient(to bottom, #a3c585 5%, #506c2d 100%);
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.product-button:hover {
  background: linear-gradient(to bottom, #b5d698 5%, #4b662b 100%);
  transform: scale(1.05);
}

.product-button:active {
  transform: scale(0.98);
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .product {
      width: 22rem;
      height: auto;
  }

  .product-image {
      height: 16rem;
  }

  .product-description {
      font-size: 0.9rem;
  }

  .product-title {
      font-size: 1.2rem;
  }

  .button-section {
      position: relative;
      padding: 1rem;
  }

  .product-button {
      width: 100%;
  }
}

.home {
  max-width: 80rem;
  margin: 0 auto;
  z-index: 9999;
  min-height: 100%;
  padding-bottom: 3rem;
  background-color: #f7f7f2;
  margin-top: 4rem;
}

.home-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
  color: #2c5f2d;
}

.description {
  font-size: 1.3rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #4a4a4a;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

.home-sub-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #3d7320;
  text-align: center;
  margin-bottom: 1.5rem;
}

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  gap: 2.5rem;
  padding: 2rem;
}

.products > * {
  background: #fff;
  border: 1px solid #d4e157; /* Subtle border */
  border-radius: 12px;
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

/* .products > *:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
} */

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .home-title {
    padding: 0 0.2rem;
    margin-top: 0.5rem;
    font-size: 1.6rem;
  }

  .description {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .home-sub-title {
    text-align: center;
    font-size: 1.5rem;
  }

  .products {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

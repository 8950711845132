.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 1.5rem;
  height: 4rem;
  width: 98.5vw;
  top: 0;
  position: fixed;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.logo {
  background-image: url(../../media/vzp-logo.png);
  height: 4rem;
  width: 7rem;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1rem;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  margin: 0 0.5rem;
}

.menu a {
  text-decoration: none;
  color: #2c5f2d; /* Nature-inspired deep green */
  font-weight: 600;
}

.menu li a {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  background-color: #f4f4f4;
  color: #333;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.menu li a:hover {
  background-color: #0f5d0f; 
  color: #fff;
  transform: scale(1.05);
}

.menu li a.active {
  background-color: #0f5d0f;
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.menu-toggle {
  display: none;
}

/* Mobile Navigation */
@media only screen and (max-width: 768px) {
  .navigation {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    height: auto;
  }

  .navigation.open {
    height: auto;
  }

  .logo {
    margin-top: 0;
    height: 3rem;
    width: 6rem;
  }

  .menu {
    flex-direction: column;
    width: 100%;
    background-color: #f7f7f2;
    border-radius: 0.5rem;
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    visibility: hidden;
  }

  .menu.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  .menu li {
    margin: 0.5rem 0;
  }

  /* .menu li a {
    background-color: transparent;
    padding: 0.8rem;
    width: 100%;
    display: block;
    transition: all 0.3s ease;
  } */

  .menu li a {
    display: inline-block;
    padding: 0.6rem 1.2rem;
    background-color: #f4f4f4;
    color: #333;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }

  /* .menu li a.active {
    background-color: rgba(15, 93, 15, 0.7);
    color: #fff;
  }
   */
  /* Active State */
.menu li a.active {
  background-color: #0f5d0f; /* Slightly darker green */
  color: #fff;
  font-weight: bold;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

  /* .menu li a:hover {
    background-color: rgba(15, 93, 15, 0.5);
    color: #fff;
  } */

  .menu li a:hover {
    background-color: #127a12; /* Slightly lighter green */
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .menu-toggle {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 1.2rem;
    right: 1rem;
    z-index: 2;
    font-size: 2rem;
    color: #0f5d0f;
  }
}


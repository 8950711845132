.slideshow-container {
  width: 100%;
  position: relative;
  top: 1rem;
  overflow: hidden;
}

.each-slide {
  width: 100%;
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.each-slide > div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.9;
  transition: opacity 0.5s ease-in-out;
}

.each-slide:hover > div {
  opacity: 1;
}

/* Mobile Responsive Design */
@media (max-width: 768px) {
  .slideshow-container {
      top: 0;
  }

  .each-slide {
      height: 18rem;
  }
}
